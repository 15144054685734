mat-form-field.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
mat-form-field.mat-form-field.mat-form-field-type-mat-select.mat-form-field-should-float
  .mat-form-field-label {
  width: 100% !important;
  background-color: #ffff !important;
  top: -11px !important;
  padding-top: 9px !important;
}

mat-form-field.mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
mat-form-field.mat-form-field.mat-form-field-should-float
  .mat-form-field-label {
  width: 100% !important;
  background-color: #ffff !important;
  font-weight: 700 !important;
}

mat-form-field.mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float
  input.mat-form-field-autofill-control
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  background-color: #ffff !important;
  top: -11px !important;
  padding-top: 9px !important;
}

mat-form-field.mat-form-field input:hover:enabled,
mat-form-field.mat-form-field input:hover {
  background-color: #f7f7f7 !important;
}

mat-form-field.mat-form-field input:focus,
mat-form-field.mat-form-field input:focus:enabled {
  background-color: #f7f7f7 !important;
}

mat-form-field.mat-form-field input,
mat-form-field.mat-form-field textarea {
  background-color: #f7f7f7 !important;
  border-color: #cfd0d1 !important;
}

mat-form-field.mat-form-field.mat-form-field-invalid.ng-invalid textarea,
mat-form-field.mat-form-field.mat-form-field-type-mat-select
  .mat-select.mat-select-invalid,
mat-form-field.mat-form-field.mat-form-field-invalid.ng-invalid input {
  border-bottom: 1.4px solid hsl(0, 92%, 53%) !important;
}

.cdk-overlay-container
  .mat-select-panel
  mat-option.mat-option:not(.mat-option-multiple).mat-active {
  background-color: #e0e2e5 !important;
}

mat-form-field.mat-form-field.mat-form-field-type-mat-select.mat-form-field-can-float
  .mat-select-value {
  background-color: #f7f7f7 !important ;
  font-weight: 300 !important;
  font-size: 14px !important;
}

mat-form-field.mat-form-field.mat-form-field-type-mat-select
  .mat-select-arrow-wrapper {
  background-color: #f7f7f7 !important;
}

mat-table.mat-table
  mat-header-row.mat-header-row
  mat-header-cell.mat-header-cell.column-small {
  background-image: url(assets/img/header.png);
}

mat-table.mat-table
  mat-header-row.mat-header-row
  mat-header-cell.mat-header-cell.column-large {
  background-image: url(assets/img/header.png);
}

mat-form-field.mat-form-field.mat-form-field-type-mat-select
  .mat-form-field-wrapper
  .mat-form-field-flex {
  background-color: #ffff !important;
}

::ng-deep
  mat-form-field.mat-form-field.mat-form-field-disabled
  .mat-form-field-flex {
  background-color: #ffff !important;
}

mat-form-field.mat-form-field.mat-form-field-type-mat-select .mat-select {
  margin-top: 0.4rem !important;
  padding-top: 16px !important;
  padding-bottom: 0px !important;
  border-bottom: 1px solid #cfd0d1 !important;
}

mat-form-field.mat-form-field.mat-form-field-can-float .mat-input-element {
  margin-top: 17px !important;
  padding-top: 25px !important;
  padding-bottom: 8px !important;
  font-weight: 300 !important;
  font-size: 14px !important;
}

.mat-placeholder-required {
  display: none !important;
}

mat-form-field.mat-form-field {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.mat-error-class,
.mat-error {
  margin-top: -18px !important;
  margin-bottom: 18px !important;
}

mat-form-field.mat-form-field.mat-form-field-type-mat-select
  .mat-select:not(.mat-select-disabled)
  .mat-select-trigger:hover,
mat-form-field.mat-form-field.mat-form-field-type-mat-select
  .mat-select:not(.mat-select-disabled)
  .mat-select-trigger {
  background-color: #f7f7f7 !important;
}

mat-form-field.mat-form-field.mat-form-field-type-mat-select
  .mat-select
  .mat-select-placeholder,
.mat-select-value-text {
  position: absolute;
  margin-top: -19px;
  width: calc(100% - 50px);
}

.mat-cell {
  font-size: 14px !important;
}

mat-option.mat-option {
  font-weight: 300 !important;
  font-size: 14px !important;
}

mat-expansion-panel.mat-expansion-panel,
mat-expansion-panel.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.panel-body {
  margin-top: 20px !important;
}
.mat-expansion-panel-body {
  padding: 0 24px 16px !important;
}
.mat-expansion-panel-header {
  padding: 0 24px !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.card-header-label {
  font-size: 16px !important;
}

snack-bar-container.mat-snack-bar-container.success {
  background-color: #ecf7e8 !important;
  color: #70bf54 !important;
  padding: 14px 16px !important;
}

snack-bar-container.mat-snack-bar-container.success * {
  color: #70bf54 !important;
  font-weight: 700 !important;
}
snack-bar-container.mat-snack-bar-container.success
  .mat-simple-snackbar:before {
  content: "\eb0a" !important;
  margin-top: -6px;
  margin-left: -12px;
}
snack-bar-container.mat-snack-bar-container.warning {
  background-color: #fff4e0 !important;
  color: #fcaf17 !important;
  padding: 14px 16px !important;
}
snack-bar-container.mat-snack-bar-container.warning * {
  color: #fcaf17 !important;
  font-weight: 700 !important;
}
snack-bar-container.mat-snack-bar-container.warning
  .mat-simple-snackbar:before {
  content: "\eb0c" !important;
  margin-top: -6px;
  margin-left: -12px;
}

snack-bar-container.mat-snack-bar-container.error {
  background-color: #f9d3d4 !important;
  color: #e11f26 !important;
  padding: 14px 16px !important;
}

snack-bar-container.mat-snack-bar-container.error * {
  color: #e11f26 !important;
  font-weight: 700 !important;
}
snack-bar-container.mat-snack-bar-container.error .mat-simple-snackbar:before {
  margin-top: -6px;
  margin-left: -12px;
  content: "\eb08" !important;
}
.mat-list-item p {
  font-weight: 300 !important;
}

mat-sidenav-container .sidenav-header .title {
  color: #bfc0c2 !important;
  font-weight: 300 !important;
}

mat-form-field.mat-form-field .mat-form-field-suffix {
  margin-top: 24px !important;
}
@media screen and (min-width: 481px) {
  mat-dialog-container.mat-dialog-container {
    box-shadow: 0px 0px 8px rgb(0 0 0 / 25%) !important;
    min-width: 300px !important;
    padding-top: 24px !important;
  }
}

mat-paginator.mat-paginator .mat-paginator-container {
  justify-content: flex-end !important;
}
mat-paginator.mat-paginator
  .mat-paginator-container
  .mat-paginator-page-size
  .mat-paginator-page-size-select {
  min-width: 80px !important;
}
mat-paginator.mat-paginator
  .mat-paginator-container
  .mat-paginator-page-size
  .mat-paginator-page-size-select
  .mat-select {
  min-width: 86px !important;
}

mat-paginator.mat-paginator
  .mat-paginator-container
  .mat-paginator-page-size
  .mat-paginator-page-size-select
  .mat-select
  > div {
  min-width: 86px !important;
}
button.mat-button:active {
  color: black !important;
  background-color: transparent !important;
}
button.mat-button:hover {
  color: black !important;
  background-color: transparent !important;
}
button.mat-button {
  color: black !important;
}

mat-form-field.mat-form-field input:active:enabled,
mat-form-field.mat-form-field input.active,
mat-form-field.mat-form-field input:focus:enabled {
  border-color: #cfd0d1 !important;
  background-position: 0% 100% !important;
  background-color: #efeff0 !important;
  box-shadow: none !important;
}

mat-form-field.mat-form-field textarea:active:enabled,
mat-form-field.mat-form-field textarea.active,
mat-form-field.mat-form-field textarea:focus:enabled {
  border-color: #cfd0d1 !important;
  background-position: 0% 100% !important;
  background-color: #efeff0 !important;
  box-shadow: none !important;
}

.mat-menu-panel.mat-menu-before .mat-menu-content:after,
.mat-menu-panel.mat-elevation-z4.mat-menu-before .mat-menu-content:after {
  display: none !important;
}
.mat-menu-panel,
.mat-menu-panel.mat-elevation-z4 {
  border-radius: 4px !important;
}
mat-table.mat-table mat-row.mat-row mat-cell.mat-cell:nth-child(odd) {
  background-color: #ffff !important;
}
mat-table.mat-table mat-row.mat-row mat-cell.mat-cell:nth-child(even) {
  background-color: #f7f7f7 !important;
}


.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
  background-color: #ffffff !important;
}
mat-form-field.mat-form-field
  .mat-form-field-suffix
  > *:not(mat-datepicker-toggle) {
  background-color: #ffffff !important;
}
mat-form-field.mat-form-field .mat-form-field-suffix {
  border-bottom-color: #c4c4c5 !important;
  min-width: 41px !important;
}
mat-form-field.mat-form-field.mat-form-field-disabled .mat-form-field-suffix {
  background-color: #ffffff !important;
  border-bottom-color: #f7e8e8 !important;
}

button.mat-button:focus{ 
  background-color: #ffff !important;
}
.cdk-overlay-container .mat-select-panel 
mat-option.mat-option:not(.mat-option-multiple).mat-active{
color:unset !important;
}