$path-to-assets: "~@bci-web-core/common-styles";
@import "@bci-web-core/material-theme/sass/theme";
@import "@bci-web-core/common-styles/sass/bci-styles";
@import url("./material_themes.scss");
@import '@ctrl/ngx-emoji-mart/picker';

$rb-extended-breakpoints: false; // whether to use extended breakpoints xxl and xxxl

@font-face {
  font-family: Bosch Sans Regular;
  src: url(assets/typo/BoschSans-Regular.otf) format("opentype"),
    url(assets/typo/BoschSans-Regular.ttf) format("truetype"),
    url(assets/typo/BoschSans-Regular.woff) format("woff");
}

@font-face {
  font-family: Bosch Sans Medium;
  src: url(assets/typo/BoschSans-Medium.otf) format("opentype"),
    url(assets/typo/BoschSans-Medium.eot) format("eot"),
    url(assets/typo/BoschSans-Medium.svg) format("svg"),
    url(assets/typo/BoschSans-Medium.ttf) format("truetype"),
    url(assets/typo/BoschSans-Medium.woff) format("woff");
}

@font-face {
  font-family: Bosch Sans Bold;
  src: url(assets/typo/BoschSans-Bold.otf) format("opentype"),
    url(assets/typo/BoschSans-Bold.eot) format("eot"),
    url(assets/typo/BoschSans-Bold.svg) format("svg"),
    url(assets/typo/BoschSans-Bold.ttf) format("truetype"),
    url(assets/typo/BoschSans-Bold.woff) format("woff");
}

@font-face {
  font-family: Bosch Sans Cond Regular;
  src: url(assets/typo/BoschSansCond-Regular.otf) format("opentype"),
    url(assets/typo/BoschSansCond-Regular.eot) format("eot"),
    url(assets/typo/BoschSansCond-Regular.svg) format("svg"),
    url(assets/typo/BoschSansCond-Regular.ttf) format("truetype"),
    url(assets/typo/BoschSansCond-Regular.woff) format("woff");
}

@font-face {
  font-family: Bosch Sans Cond Bold;
  src: url(assets/typo/BoschSansCond-Bold.otf) format("opentype"),
    url(assets/typo/BoschSansCond-Bold.eot) format("eot"),
    url(assets/typo/BoschSansCond-Bold.svg) format("svg"),
    url(assets/typo/BoschSansCond-Bold.ttf) format("truetype"),
    url(assets/typo/BoschSansCond-Bold.woff) format("woff");
}

@font-face {
  font-family: Bosch Sans Light;
  src: url(assets/typo/BoschSans-Light.otf) format("opentype"),
    url(assets/typo/BoschSans-Light.eot) format("eot"),
    url(assets/typo/BoschSans-Light.svg) format("svg"),
    url(assets/typo/BoschSans-Light.ttf) format("truetype"),
    url(assets/typo/BoschSans-Light.woff) format("woff");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(assets/typo/MaterialIcons-Regular.eot) format("eot"),
    url(assets/typo/MaterialIcons-Regular.ttf) format("truetype"),
    url(assets/typo/MaterialIcons-Regular.woff) format("woff"),
    url(assets/typo/MaterialIcons-Regular.woff2) format("woff2");
}

body {
  overflow: hidden;
}

.bci-content {
  height: inherit;
}
.rb-ic{
  cursor: pointer;
}

.bci-content .bci-content-container {
  padding-top: 0 !important;
  height: inherit !important;
}

.bci-content .bci-content-container::before {
  background: none !important;
}
.content-container {
  position: relative !important;
  min-height: 48vh;
}
.bci-side-bar .mat-drawer-inner-container {
  height: max-content !important;
}

.bci-side-bar .bci-sidebar-footer-separator {
  border: none !important;
}

.burger-line.burgerTop {
  -ms-transform: translate(0px, 8px) scale(1) rotate(-45deg) !important;
}

.burger-line.burgerBottom {
  -ms-transform: translate(1px, -5px) scale(1) rotate(45deg) !important;
}

.mat-drawer-inner-container {
  -ms-overflow-x: hidden !important;
  -ms-overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

.rb-ribbon {
  padding: 0;
  height: 1.5vh !important;
  background: url(assets/img/header/header_ribbon.png);
  width: 100%;
  background-size: 100% 16px;
}

.tool-bar {
  border-radius: 3px;
  background: #ffffff;
}

.align-right {
  margin-left: 4%;

  .mat-figure {
    justify-content: flex-end !important;
  }
}

.demo-tab-group .mat-tab-label-content {
  height: 50px;
  color: #005691;
  font-weight: 200;
  font-size: 0.875rem;
}

.demo-tab-group .mat-tab-label-content:focus {
  color: #000000;
  font-weight: bold;
}

.hide-side-nav .mat-sidenav {
  display: none !important;
}

.hide-side-nav .burger {
  display: none !important;
}

.hide-side-nav .mat-drawer-transition .mat-drawer-content {
  margin-left: 0 !important;
}

.mat-form-field-flex {
  background: none !important;
}

.select-arrow .mat-select-arrow-wrapper {
  vertical-align: text-top;
}

.form-select .mat-form-field-label {
  font-weight: normal !important;
}

.stretched-tabs {
  max-width: 100%;
}

.stretched-tabs .mat-tab-label {
  margin-right: 0 !important;
  flex-basis: auto !important;
}

.table-bottom-space {
  .mat-row,
  .mat-header-row {
    max-width: 100%;
  }
}

.close-btn {
  float: right;
}

.modify-button {
  cursor: pointer;
  margin: 0.6rem;
}

_:-ms-lang(x),
_:-webkit-full-screen,
.edge-select {
  width: 11.25rem;
}

.mat-snack-bar-container {
  max-width: 100% !important;
  margin-top: 10.4vh !important;
}

.cell-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.mat-dialog-container {
  max-width: 50rem;
  border-radius: 5px !important;
  max-height: 75vh !important;
}

.popup-body {
  margin-right: 1.5625rem;
}

.popup-content {
  display: inline;
}

.popup-content p {
  margin-bottom: 1.25rem;
}

.search-bar .mat-form-field-underline {
  display: none !important;
}

button {
  cursor: pointer;
}

.cdk-global-scrollblock {
  overflow-y: inherit !important;
}

.rb-spinner .rb-loader {
  position: fixed;
  top: 45%;
  left: 50%;
  z-index: 1;
}

.blur-effect > :not(.cdk-overlay-container) {
  filter: blur(5px);
}

.content {
  background-color: #f2f2fa;
}

.overview-container {
  display: flex;
  justify-content: space-between;

  .heading {
    font-style: Bosch Sans Light;
    font-size: 20px;
    height: auto;
    opacity: 100%;
    margin-bottom: 0.6rem;
  }

  .main {
    background-color: white;
    margin: 1rem;
    flex-grow: 1;
    width: 90%;
    overflow: auto;
    padding: 0;
  }

  .aside-nav {
    width: 200px;
    text-align: center;
    color: #868789;
  }

  .aside-nav:last-child {
    margin-bottom: 1rem;
  }
}

.overview-mat-card {
  height: 21.75rem;
  background: white !important;
  opacity: 1;
  padding: 1.25rem;
}

.overview-button {
  padding: 0.625rem 1.25rem;
  background: #005691;
  color: #ffffff;
  font-size: 14px;
  margin: 1.5625rem 1.5625rem 1.5625rem 0;
}

.overview-status-size {
  font-size: 2.125rem;
}

.overview-text {
  font-size: 16px;
  line-height: 1.8;
  padding: 0 1.5rem;
}

.manage-action-btn {
  margin: 0 0.6rem 0.6rem 0 !important;
  border: 1px solid #043756 !important;
}

.manage-label {
  margin-left: 1rem;
  font-family: Bosch Sans Light;
  font-size: 20px;
  color: #293036;
}

.icon-space {
  margin: 0.125rem;
}

.table-container {
  overflow-x: auto;
}

.table-container .table-data {
  min-width: 768px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .img-resize {
    height: 4.375rem !important;
  }

  .lang-li {
    width: auto !important;
  }

  .role-div {
    width: auto !important;
  }

  .drop-atag {
    padding-left: 0.5rem !important;
  }

  .search-section .mandatory {
    margin: 2rem 0 !important;
  }
}

.quickLinks-overlay-container {
  height: 83vh;
  background-color: black;
  opacity: 0.72;
}

.quickLinks-overlay .mat-drawer-container,
.mat-drawer-content {
  background: none !important;
}

.quickLinks-overlay .mat-drawer-inner-container {
  height: 83vh !important;
}

.ng-untouched::placeholder {
  color: rgba(0, 0, 0, 0.42) !important;
}

.ng-touched::placeholder {
  color: rgba(0, 0, 0, 0.42) !important;
}

.mat-menu-item {
  display: flex !important;
  align-items: center !important;
}

.button {
  margin-left: 0.6rem !important;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  font-size: 14px;
  font-family: Bosch Sans Light;
  box-shadow: none;
  border: 1px solid #043756 !important;
  width: 6.2rem;
  text-align: center;
}

.btn-background {
  background-image: url(assets/img/header.png) !important;
}

.label {
  color: #000000;
  font-size: 16px;
  font-style: Bosch Sans Bold;
  height: 4em;
}

.mat-progress-bar-fill::after {
  background: #1f9e3a !important;
}

.header-style {
  background-image: url(assets/img/header.png);
  width: 100%;
  height: 3.5rem;
  background-position: center;
}

.heading-label {
  color: white;
  font-weight: 400;
  font-size: 20px;
  padding: 1rem;
  display: flex;
}

.create-form-spacing {
  margin: 1rem;
}

.table-header-row {
  border-bottom: none !important;
}

.mat-progress-bar-account .mat-progress-bar-fill::after {
  background-color: #74b843 !important;
}

.mat-progress-bar-tenant .mat-progress-bar-fill::after {
  background-color: #0e99d7 !important;
}

.mat-progress-bar-profile .mat-progress-bar-fill::after {
  background-color: #e30613 !important;
}

.page-icon {
  margin-right: 10px;
  height: 40px;
  width: 40px;
  margin-top: -5px;
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

.blurred {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

.pie-chart {
  width: 50%;
  float: left;
}

.mat-pseudo-checkbox-disabled::after {
  color: grey !important;
}

.tag-link {
  font-size: 12px;
  font-weight: bold;
}

.tag-link-div {
  margin-left: 0.6rem;
}

.mat-optgroup-label {
  font-size: 14px !important;
  font-weight: bold !important;
}

.column-small {
  flex-grow: 1;
  width: 100%;
  height: 3.5rem;
  background-position: center;
}

.column-large {
  flex-grow: 3;
  width: 100%;
  height: 3.5rem;
  background-position: center;
}

.bci-content-container {
  position: relative !important;
}

body {
  overflow-y: auto;
}
.mat-menu-panel {
  max-width: none !important;
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-error-class,
.mat-error {
  font-size: 12px;
}

.page-icon-small {
  margin-right: 10px;
  height: 35px;
  width: 35px;
  margin-top: -5px;
}

mat-sidenav {
  background-color: #293036 !important;
}

mat-sidenav ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
mat-sidenav ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #293036;
  border-radius: 10px;
}

/* Handle */
mat-sidenav ::-webkit-scrollbar-thumb {
  background: #727578;
  border-radius: 10px;
}

/* Handle on hover */
mat-sidenav ::-webkit-scrollbar-thumb:hover {
  background: #727578;
}

.sidenav-content {
  scrollbar-color: #727578 #293036;
  scrollbar-width: thin !important;
}

.mat-list-item.active {
  border-left-color: white !important;
}

.cdk-overlay-container .mat-select-panel:not([class*="mat-elevation-z"]) {
  border: none !important;
}

.mandatory {
  color: #ff0000;
}
.account:before {
  content: url("assets/img/menu-icons/account-management.svg");
}

.tenant:before {
  content: url("assets/img/menu-icons/tenant-management.svg");
}

.profile:before {
  content: url("assets/img/menu-icons/profile-management.svg");
}

.user:before {
  content: url("assets/img/menu-icons/user-management.svg");
}

.device:before {
  content: url("assets/img/menu-icons/device-management.svg");
}

.firmware:before {
  content: url("assets/img/menu-icons/firmware-management.svg");
}

.fota:before {
  content: url("assets/img/menu-icons/fota-management.svg");
}

.scheduler:before {
  content: url("assets/img/menu-icons/scheduler-management.svg");
}

.accesskey:before {
  content: url("assets/img/menu-icons/key-management.svg");
}

.report:before {
  content: url("assets/img/menu-icons/reports.svg");
}

.certificate:before {
  content: url("assets/img/menu-icons/certificate-icon.svg");
}

.billing:before {
  content: url("assets/img/menu-icons/billing-management.svg");
}

.sms::before {
  content: url("assets/img/menu-icons/sms-management.svg");
}
.simcard::before {
  content: url("assets/img/menu-icons/simcard.svg");
}
.whatsapp::before {
  content: url("assets/img/menu-icons/whatsapp-management.svg");
}

.emoji-mart-scroll {
  height: 379px !important;
}